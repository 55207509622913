<template>
    <b-modal id="view-previous-billings" ref="view-previous-billings" size="xl" :title="title" ok-title="OK"
        @show="onReset" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true" ok-only>
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <!-- Select Actions and Items Per Page Options -->
        <b-row class="mb-2">
            <b-col sm="6" offset-sm="6" md="4" offset-md="8" class="text-md-right">
                <b-input-group size="sm" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table ref="billingReportsTable" show-empty striped hover :items="items" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

            <template v-slot:cell(chargeType)>
                <ChargeTypeStatus :billingReport="billingReport" />
            </template>

            <template v-slot:cell(totalVATInclusiveAmount)="row">
                <span class="numFont">
                    {{ formatMoneyValue('PHP', row.item.totalVATInclusiveAmount) }}
                </span>
            </template>

            <template v-slot:cell(actions)="row">
                <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
                    @click.stop="row.toggleDetails" class="mr-1 mt-1">
                    <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
                    <i class="fa fa-eye" v-else></i>
                </b-button>
            </template>

            <template slot="row-details" slot-scope="row">
                <BillingReportDetailsView :row="row" />
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
// Components
import BillingReportDetailsView from './BillingReportDetailsView.vue';
import ChargeTypeStatus from './ChargeTypeStatus.vue';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

// DAO
import billingReportsDAO from '@/database/billingReports';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'view-previous-billings',
    components: {
        BillingReportDetailsView,
        ChargeTypeStatus,
        Loading,
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'billingReportNo',
                    sortable: false,
                },
                {
                    key: 'billingNumber',
                    sortable: false,
                },
                {
                    key: 'clientAccountNo',
                    label: 'Account No',
                    sortable: false,
                },
                {
                    key: 'chargeType',
                    sortable: false,
                },
                {
                    key: 'totalVATInclusiveAmount',
                    label: 'Total Amount',
                    sortable: false,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'actions',
                    class: 'text-center'
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            billingReport: '',
            prevBillingReports: {},

            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        title() {
            return this.accountNo + ' Previous ' + this.chargeType + ' Billings';
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        accountNo() {
            return this.billingReport && this.billingReport.clientAccountNo ? this.billingReport.clientAccountNo : '-';
        },
        clientAccountId() {
            return this.billingReport && this.billingReport.clientAccountId ? this.billingReport.clientAccountId : '-';
        },
        chargeType() {
            return this.billingReport && this.billingReport.chargeType ? this.billingReport.chargeType : '-';
        }
    },
    mounted() {
        EventBus.$on('onViewPreviousBillings', async billingReport => {
            this.billingReport = { ...billingReport };
            await this.updateTable();
        });
    },
    methods: {
        async updateTable() {
            // show loading indicator
            this.isLoading = true;

            this.prevBillingReports = await billingReportsDAO.getPreviousBillingReports(this.clientAccountId, this.chargeType, ['Approved'], 20);
            this.processBillingReports(this.prevBillingReports);

            // hide loading indicator
            this.isLoading = false;
        },

        processBillingReports(billingReports) {
            this.items = Object.values(billingReports);
            this.items = _.sortBy(this.items, ['dateUpdated']);
            this.items.reverse();
            this.totalRows = this.items.length;

            // remove show details
            _.forEach(this.items, item => {
                delete item._showDetails;
            });

            // refresh table
            if (this.$refs.billingReportsTable) {
                this.$refs.billingReportsTable.refresh();
            }
        },

        async onReset() {
            // init billingReport
            this.billingReport = { ...this.$store.getters.currBillingReport };
            await this.updateTable();
        },

        // UTILS
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        }
    }
}
</script>