<template>
    <b-modal id="update-billing-period" ref="update-billing-period" size="lg" :title="title" ok-title="Update"
        @ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
        :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-form>
            <b-container>
                <div>
                    <b-row class="mt-2 mb-4 readonly-datails">
                        <b-col lg="6" md="6" sm="12">
                            <AccountDetails :billingReport="billingReport" :clientAccount="clientAccount" />
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <ChargeTypeDetails :billingReport="billingReport" />
                        </b-col>
                    </b-row>

                    <b-row class="mt-4">
                        <b-col sm="8">
                            <b>SELECT BILLING PERIOD</b>
                        </b-col>
                    </b-row>
                    <b-row class="my-2" no-gutters>
                        <b-col lg="5" md="5" sm="10" class="mr-3">
                            <b-form-group label="Start Date">
                                <b-form-datepicker name="Start Date" v-model="selStartDate" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="dateFormatOptions"
                                    v-validate="'required'" readonly />
                                <span v-show="errors.has('Start Date')" class="help-block">
                                    {{ errors.first('Start Date') }}
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="5" md="5" sm="10">
                            <b-form-group label="End Date">
                                <b-form-datepicker name="End Date" v-model="selEndDate" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="dateFormatOptions"
                                    v-validate="'required'" readonly />
                                <span v-show="errors.has('End Date')" class="help-block">
                                    {{ errors.first('End Date') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row no-gutters>
                        <b-col lg="5" md="5" sm="10" class="mr-3">
                            <b-form-group label="Due Date" :description="paymentTermDescription">
                                <b-form-datepicker name="Due Date" v-model="selDueDate" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="dateFormatOptions"
                                    v-validate="'required'" />
                                <span v-show="errors.has('Due Date')" class="help-block">
                                    {{ errors.first('Due Date') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
        </b-form>
    </b-modal>
</template>

<script>
// Components
import AccountDetails from './primaryDetails/AccountDetails';
import ChargeTypeDetails from './primaryDetails/ChargeTypeDetails';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'update-billing-period',
    components: {
        AccountDetails,
        ChargeTypeDetails,
        Loading,
    },
    data() {
        return {
            billingReport: {},
            clientAccount: {},

            selStartDate: null,
            selEndDate: null,
            selDueDate: null,

            dateFormatOptions: { ...config.dateFormatOptions },

            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        title() {
            return "Edit Billing Report " + this.billingReportNo;
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        paymentTermDescription() {
            return "Payment Term as set to " + this.clientAccount.paymentTerms + " days";
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '-';
        }
    },
    watch: {
        selStartDate: function (newVal) {
            if (newVal) {
                this.billingReport.startDate = new Date(newVal).getTime();
                this.updateTimeStamps();
            }
        },
        selEndDate: function (newVal) {
            if (newVal) {
                this.billingReport.endDate = new Date(newVal).getTime();
                this.adjustDueDate(newVal);
                this.updateTimeStamps();
            }
        },
        selDueDate: function (newVal) {
            if (newVal) {
                this.billingReport.dueDate = new Date(newVal).getTime();
                this.updateTimeStamps();
            }
        }
    },
    mounted() {
        EventBus.$on('onUpdateBillingPeriod', (param) => {
            this.$bvModal.show('update-billing-period');
            this.onReset(param);
        });
    },
    methods: {
        adjustDueDate(endDate) {
            let paymentTerms = this.clientAccount.paymentTerms ? parseInt(this.clientAccount.paymentTerms) : 0;
            let endDateTimeStamp = DateUtil.startDateTimeStamp(endDate);
            this.selDueDate = new Date(endDateTimeStamp);
            this.selDueDate = new Date(this.selDueDate.setDate(this.selDueDate.getDate() + paymentTerms));
        },
        updateTimeStamps() {
            this.billingReport.dateUpdated = DateUtil.getCurrentTimestamp();
            this.billingReport.updatedBy = this.loggedUser.id;
        },

        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            // show loading indicator
            this.isLoading = true;

            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                this.isLoading = false;
                return;
            }

            if (!this.validateBillingReport()) {
                this.isLoading = false;
                return;
            }

            this.handleSubmit();
        },
        validateBillingReport() {
            let isValid = true;

            // Validate Billing Period Details
            let startDate = DateUtil.startDateTimeStamp(new Date(this.selStartDate));
            let endDate = DateUtil.endDateTimeStamp(new Date(this.selEndDate));
            let dueDate = DateUtil.endDateTimeStamp(new Date(this.selDueDate));

            if (startDate >= endDate) {
                this.$toaster.warning('Start Date must be before End Date.');
                isValid = false;
            } else if (startDate > dueDate) {
                this.$toaster.warning('Start Date must be before Due Date.');
                isValid = false;
            } else if (endDate > dueDate) {
                this.$toaster.warning('End Date must be before Due Date.');
                isValid = false;
            }

            return isValid;
        },
        handleSubmit() {
            this.$store.commit('SET_CURR_BILLING_REPORT', this.billingReport);
            EventBus.$emit("onCloseUpdateBillingPeriod", this.billingReport);

            this.$toaster.success(`Billing Report "${this.billingReport.billingReportNo}" period was updated successfully.`);
            this.$bvModal.hide('update-billing-period');
        },

        onReset(param) {
            let billingReport = param.billingReport ? param.billingReport : {};
            if (!_.isEmpty(billingReport)) {
                this.billingReport = { ...billingReport };
                this.selStartDate = new Date(this.billingReport.startDate);
                this.selEndDate = new Date(this.billingReport.endDate);
                this.selDueDate = new Date(this.billingReport.dueDate);
            }

            let clientAccount = param.clientAccount ? param.clientAccount : {};
            if (!_.isEmpty(clientAccount)) {
                this.clientAccount = clientAccount;
            }
        }
    },
    beforeDestroy() {
        EventBus.$off('onUpdateBillingPeriod');
    },
}
</script>

<style scoped>
.readonly-datails {
    margin-bottom: 40px !important;
}

.field-label {
    font-weight: bold;
    margin-right: 10px;
    margin-top: 3px;
}

.field-value {
    font-size: 16px !important;
}
</style>