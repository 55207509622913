<template>
    <div>
        <div class="mb-4">For
            <ChargeTypeStatus :billingReport="billingReport" /> billing,
            here are the list of transactions within the billing period
            of <b>{{ startDate }}</b> and <b>{{ endDate }}</b>.
        </div>

        <!-- Select Actions and Items Per Page Options -->
        <b-row class="mt-4 mb-2">
            <b-col sm="6" offset-sm="6" md="4" offset-md="8" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>
        <b-table ref="rentalTransactionsTable" show-empty striped hover :items="items" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

            <template v-slot:cell(dateReceived)="row">
                <span class="text-nowrap">{{ getFormattedDate(row.item.dateReceived) }}</span>
            </template>

            <template v-slot:cell(dispatchNo)="row">
                <span class="numFont">
                    {{ row.item.dispatchNo }}
                </span>
            </template>

            <template v-slot:cell(notes)="row">
                <span class="truncate-text">
                    <truncate type="html" action-class="text-primary" :text="breakNotes(row.item.notes, 25)"
                        clamp="Show More" less="Show Less" :length="50" />
                </span>
            </template>

            <template v-slot:cell(type)="row">
                <span v-if="row.item.type === 'Hire'">
                    <b-badge variant="primary">{{ row.item.type }}</b-badge>
                </span>
                <span v-if="row.item.type === 'Dehire'">
                    <b-badge variant="warning">{{ row.item.type }}</b-badge>
                </span>
            </template>

            <template v-slot:cell(quantity)="row">
                <span class="numFont" v-if="row.item.type === 'Hire'">
                    {{ row.item.quantity.toLocaleString() }}&nbsp;
                </span>
                <span class="numFont" v-else>
                    ({{ row.item.quantity.toLocaleString() }})
                </span>
            </template>

            <template v-slot:cell(calendarDays)="row">
                <span class="numFont" v-if="row.item.type === 'Hire'">
                    {{ row.item.calendarDays.toLocaleString() }}&nbsp;
                </span>
                <span class="numFont" v-else>
                    ({{ (-1 * row.item.calendarDays).toLocaleString() }})
                </span>
            </template>

            <template v-slot:cell(totalDays)="row">
                <span class="numFont" v-if="row.item.type === 'Hire'">
                    {{ row.item.totalDays.toLocaleString() }}&nbsp;
                </span>
                <span class="numFont" v-else>
                    ({{ (-1 * row.item.totalDays).toLocaleString() }})
                </span>
            </template>

            <template v-slot:cell(calendarHours)="row">
                <span class="numFont" v-if="row.item.type === 'Hire'">
                    {{ row.item.calendarHours.toLocaleString() }}&nbsp;
                </span>
                <span class="numFont" v-else>
                    ({{ (-1 * row.item.calendarHours).toLocaleString() }})
                </span>
            </template>

            <template v-slot:cell(totalHours)="row">
                <span class="numFont" v-if="row.item.type === 'Hire'">
                    {{ row.item.totalHours.toLocaleString() }}&nbsp;
                </span>
                <span class="numFont" v-else>
                    ({{ (-1 * row.item.totalHours).toLocaleString() }})
                </span>
            </template>

        </b-table>
        <b-row>
            <b-col md="8" sm="12">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-4">
            <b-col lg="6" md="6" sm="12" class="mt-1">
                <span class="field-label">Beginning Assets-on-Hire</span>
                <span class="field-value numFont" v-if="startingQuantity >= 0">
                    {{ startingQuantity.toLocaleString() }}
                </span>
                <span class="field-value numFont" v-else>
                    ({{ (-1 * startingQuantity).toLocaleString() }})
                </span>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="mt-1">
                <span class="field-label">Ending Assets-on-Hire</span>
                <span class="field-value numFont" v-if="endingQuantity >= 0">
                    {{ endingQuantity.toLocaleString() }}
                </span>
                <span class="field-value numFont" v-else>
                    ({{ (-1 * endingQuantity).toLocaleString() }})
                </span>
            </b-col>
        </b-row>
        <b-row class="mt-2" v-if="rentFrequency === 'Per Day'">
            <b-col lg="6" md="6" sm="12" class="mt-1">
                <span class="field-label">Beginning Assets Days for the Period</span>
                <span class="field-value numFont" v-if="startingDays >= 0">
                    {{ startingDays.toLocaleString() }}
                </span>
                <span class="field-value numFont" v-else>
                    ({{ (-1 * startingDays).toLocaleString() }})
                </span>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="mt-1">
                <span class="field-label">Ending Assets Days for the Period</span>
                <span class="field-value numFont" v-if="endingDays >= 0">
                    {{ endingDays.toLocaleString() }}
                </span>
                <span class="field-value numFont" v-else>
                    ({{ (-1 * endingDays).toLocaleString() }})
                </span>
            </b-col>
        </b-row>
        <b-row class="mt-2" v-else>
            <b-col lg="6" md="6" sm="12" class="mt-1">
                <span class="field-label">Beginning Assets Hours for the Period</span>
                <span class="field-value numFont" v-if="startingHours >= 0">
                    {{ startingHours.toLocaleString() }}
                </span>
                <span class="field-value numFont" v-else>
                    ({{ (-1 * startingHours).toLocaleString() }})
                </span>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="mt-1">
                <span class="field-label">Ending Assets Hours for the Period</span>
                <span class="field-value numFont" v-if="endingHours >= 0">
                    {{ endingHours.toLocaleString() }}
                </span>
                <span class="field-value numFont" v-else>
                    ({{ (-1 * endingHours).toLocaleString() }})
                </span>
            </b-col>
        </b-row>

    </div>
</template>

<script>
// Components
import ChargeTypeStatus from '../ChargeTypeStatus.vue';

// Utils
import { DateUtil } from '@/utils/dateutil';

// DAO
import dispatchDAO from '@/database/dispatches';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';


export default {
    name: 'billing-transactions-rental-tab',
    components: {
        ChargeTypeStatus,
        Loading,
        truncate
    },
    props: {
        clientAccount: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            items: [],
            fields: [],
            defaultFields: [
                {
                    key: 'dateReceived',
                    label: 'Date',
                    sortable: false,
                },
                {
                    key: 'dispatchNo',
                    label: 'Dispatch No.',
                    sortable: false,
                },
                {
                    key: 'notes',
                    sortable: false,
                },
                {
                    key: 'type',
                    sortable: false,
                },
                {
                    key: 'quantity',
                    sortable: false,
                    class: 'text-right'
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            billingReport: {},
            assetPoolDistribution: {},
            dispatches: {},
            totalDays: 0,
            totalHours: 0,

            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        contractStartDate() {
            return this.clientAccount && this.clientAccount.startDate ? this.clientAccount.startDate : 0;
        },
        startDate() {
            return DateUtil.getFormattedDate(this.billingReport.startDate);
        },
        endDate() {
            return DateUtil.getFormattedDate(this.billingReport.endDate);
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '';
        },
        assetOwnerId() {
            return this.billingReport && this.billingReport.assetOwnerId ? this.billingReport.assetOwnerId : '';
        },
        clientId() {
            return this.billingReport && this.billingReport.clientId ? this.billingReport.clientId : '';
        },
        clientAccountId() {
            return this.billingReport && this.billingReport.clientAccountId ? this.billingReport.clientAccountId : '';
        },
        chargeTypeDetails() {
            return this.billingReport && this.billingReport.chargeTypeDetails ? this.billingReport.chargeTypeDetails : {};
        },
        assetTypeId() {
            return this.chargeTypeDetails.assetTypeId ? this.chargeTypeDetails.assetTypeId : '';
        },
        rentFrequency() {
            return this.chargeTypeDetails.rentFrequency ? this.chargeTypeDetails.rentFrequency : '';
        },
        rentRate() {
            return this.chargeTypeDetails.rentRate ? this.chargeTypeDetails.rentRate : 0;
        },
        startingQuantity() {
            return this.chargeTypeDetails.startingQuantity ? this.chargeTypeDetails.startingQuantity : 0;
        },
        endingQuantity() {
            return this.chargeTypeDetails.endingQuantity ? this.chargeTypeDetails.endingQuantity : 0;
        },
        startingDays() {
            return this.chargeTypeDetails.startingDays ? this.chargeTypeDetails.startingDays : 0;
        },
        endingDays() {
            return this.chargeTypeDetails.endingDays ? this.chargeTypeDetails.endingDays : 0;
        },
        startingHours() {
            return this.chargeTypeDetails.startingHours ? this.chargeTypeDetails.startingHours : 0;
        },
        endingHours() {
            return this.chargeTypeDetails.endingHours ? this.chargeTypeDetails.endingHours : 0;
        }
    },
    async mounted() {
        // init billingReport
        this.billingReport = { ...this.$store.getters.currBillingReport };
        await this.onReset(this.billingReport);

        EventBus.$on("onUpdateTransactionsRental", async billingReport => {
            await this.onReset(billingReport);
        });
    },
    methods: {
        async onReset(billingReport) {
            this.billingReport = billingReport;
            if (this.billingReport && !_.isEmpty(this.billingReport)) {
                this.updateTableFields();
                await this.retrieveTransactions();
            }
        },
        updateTableFields() {
            this.fields = [...this.defaultFields];

            if (this.rentFrequency === 'Per Day') {
                this.fields.push({
                    key: 'calendarDays',
                    label: 'Calendar Days',
                    sortable: false,
                    class: 'text-right'
                });
                this.fields.push({
                    key: 'totalDays',
                    label: 'Total Days',
                    sortable: false,
                    class: 'text-right'
                });
            } else if (this.rentFrequency === 'Per Hour') {
                this.fields.push({
                    key: 'calendarHours',
                    label: 'Hours',
                    sortable: false,
                    class: 'text-right'
                });
                this.fields.push({
                    key: 'totalHours',
                    label: 'Total Hours',
                    sortable: false,
                    class: 'text-right'
                });
            }

            // refresh table
            if (this.$refs.rentalTransactionsTable) {
                this.$refs.rentalTransactionsTable.refresh();
            }
        },
        async retrieveTransactions() {
            try {
                // show loading indicator
                this.isLoading = true;

                let filter = {
                    status: 'Received',
                    receivedStartDate: this.contractStartDate,
                    receivedEndDate: this.billingReport.endDate,
                    accountNo: { id: this.clientAccountId },
                };

                let sourceFilter = { ...filter };
                sourceFilter.sourceCompany = { id: this.assetOwnerId };
                sourceFilter.destinationCompany = { id: this.clientId };
                let sourceDispatches = await dispatchDAO.getDispatches(sourceFilter);

                let destinationFilter = { ...filter };
                destinationFilter.sourceCompany = { id: this.clientId };
                destinationFilter.destinationCompany = { id: this.assetOwnerId };
                let destinationDispatches = await dispatchDAO.getDispatches(destinationFilter);

                this.dispatches = { ...sourceDispatches, ...destinationDispatches };

                // Include only dispatch with assetTypeId
                this.dispatches = _.filter(this.dispatches, dispatch => {
                    let isIncluded = false;
                    let dispatchAsset = this.getDispatchAsset(dispatch, this.assetTypeId);
                    if (dispatchAsset && !_.isEmpty(dispatchAsset)) {
                        isIncluded = true;
                    }
                    return isIncluded;
                });

                this.processDispatches(this.dispatches);
            } catch (error) {
                this.$toaster.error('Error loading transactions. Please reload the page again.');
            }

            // hide loading indicator
            this.isLoading = false;
        },
        getDispatchAsset(dispatch, assetTypeId) {
            return _.find(dispatch.assets, o => {
                return o.assetTypeId === assetTypeId;
            });
        },

        processDispatches(dispatches) {
            let beforeBillingPeriodDispatches = _.filter(dispatches, o => {
                return o.dateReceived < DateUtil.startDateTimeStamp(this.billingReport.startDate);
            });
            this.processBeginningBalance(beforeBillingPeriodDispatches);

            let withinBillingPeriodDispatches = _.filter(dispatches, o => {
                return o.dateReceived >= DateUtil.startDateTimeStamp(this.billingReport.startDate);
            });
            this.processAdjustmentFromAssetMovement(withinBillingPeriodDispatches);
        },
        processBeginningBalance(dispatches) {
            let startingQuantity = 0;

            _.forEach(dispatches, dispatch => {
                let sourceCompanyId = dispatch.source.companyId;
                let type = sourceCompanyId === this.assetOwnerId ? 'Hire' : 'Dehire';
                let dispatchAsset = this.getDispatchAsset(dispatch, this.assetTypeId);
                let quantity = dispatchAsset.actualQuantity;

                if (type === 'Hire') {
                    startingQuantity += quantity;
                } else if (type === 'Dehire') {
                    startingQuantity -= quantity;
                }
            });

            // Beginning Quantity
            this.billingReport.chargeTypeDetails.startingQuantity = startingQuantity;

            if (this.rentFrequency === 'Per Day') {
                // Beginning Days
                let calendarDays = this.getCalendarDays(this.billingReport.startDate, this.billingReport.endDate);
                this.billingReport.chargeTypeDetails.startingDays = this.getTotalDays(calendarDays, startingQuantity);
            } else if (this.rentFrequency === 'Per Hour') {
                // Beginning Hours
                let calendarHours = this.getCalendarHours(this.billingReport.startDate, this.billingReport.endDate);
                this.billingReport.chargeTypeDetails.startingHours = this.getTotalHours(calendarHours, startingQuantity);
            }
        },
        processAdjustmentFromAssetMovement(dispatches) {
            let adjustedQuantity = 0;

            // reset
            this.items = [];
            _.forEach(dispatches, dispatch => {
                let item = { ...dispatch };

                let sourceCompanyId = dispatch.source.companyId;
                item.type = sourceCompanyId === this.assetOwnerId ? 'Hire' : 'Dehire';
                let dispatchAsset = this.getDispatchAsset(dispatch, this.assetTypeId);
                item.quantity = dispatchAsset.actualQuantity;

                if (this.rentFrequency === 'Per Day') {
                    let calendarDays = this.getCalendarDays(item.dateReceived, this.endDate);
                    item.calendarDays = item.type === 'Hire' ? calendarDays : calendarDays * -1;

                    item.totalDays = this.getTotalDays(item.calendarDays, item.quantity);
                } else if (this.rentFrequency === 'Per Hour') {
                    let calendarHours = this.getCalendarHours(item.dateReceived, this.endDate);
                    item.calendarHours = item.type === 'Hire' ? calendarHours : calendarHours * -1;

                    item.totalHours = this.getTotalHours(item.calendarHours, item.quantity);
                }

                if (item.type === 'Hire') {
                    adjustedQuantity += item.quantity;
                } else if (item.type === 'Dehire') {
                    adjustedQuantity -= item.quantity;
                }

                this.items.push(item);
            });
            this.totalRows = this.items.length;

            // sort by ascending order
            this.items = _.sortBy(this.items, ['dateReceived']);
            // refresh table
            if (this.$refs.rentalTransactionsTable) {
                this.$refs.rentalTransactionsTable.refresh();
            }
            
            this.updateComputationSummary(adjustedQuantity);
        },

        getCalendarDays(startDate, endDate) {
            // +1 for including the startDate
            return DateUtil.getNoOfDays(startDate, endDate) + 1;
        },
        getTotalDays(calendarDays, quantity) {
            return calendarDays * quantity;
        },
        getCalendarHours(startDate, endDate) {
            let noOfDays = DateUtil.getNoOfDays(startDate, endDate);
            return noOfDays * 8;
        },
        getTotalHours(calendarHours, quantity) {
            return calendarHours * quantity;
        },

        updateComputationSummary(adjustedQuantity) {
            // Ending Quantity
            let startingQuantity = this.billingReport.chargeTypeDetails.startingQuantity;
            this.billingReport.chargeTypeDetails.endingQuantity = startingQuantity + adjustedQuantity;

            if (this.rentFrequency === 'Per Day') {
                let startingDays = this.billingReport.chargeTypeDetails.startingDays;
                let adjustmentDays = _.sum(_.map(this.items, 'totalDays'));
                this.totalDays = startingDays + adjustmentDays;

                // Ending Days
                this.billingReport.chargeTypeDetails.endingDays = this.totalDays;

            } else if (this.rentFrequency === 'Per Hour') {
                let startingHours = this.billingReport.chargeTypeDetails.startingHours;
                let adjustmentHours = _.sum(_.map(this.items, 'totalHours'));
                this.totalHours = startingHours + adjustmentHours;

                // Ending Hours
                this.billingReport.chargeTypeDetails.endingHours = this.totalHours;
            }

            // update transactions
            this.billingReport.transactions = this.items;
            // update total charge type amount
            this.billingReport.totalChargeTypeAmount = this.getTotalRentalAmount();

            this.$emit('onUpdateBillingReport', this.billingReport);
            EventBus.$emit('onUpdateChargeTypeAmount', this.billingReport.totalChargeTypeAmount);
        },
        getTotalRentalAmount() {
            let totalAmount = 0;
            if (this.rentFrequency === 'Per Day') {
                totalAmount = this.rentRate * this.totalDays;
            } else if (this.rentFrequency === 'Per Hour') {
                totalAmount = this.rentRate * this.totalHours;
            }
            return parseFloat(totalAmount);
        },

        // UTILS
        getFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        },
        breakNotes(notes, length) {
            return notes.length > length ? notes.replace(new RegExp(`(.{1,${length}})\\s`, 'g'), '$1<br>') : notes;
        }
    },
    beforeDestroy() {
        EventBus.$off('onUpdateTransactionsRental');
    },
}
</script>

<style scoped>
.field-label {
    font-weight: bold;
    margin-right: 5px;
    margin-top: 3px;
    font-size: 16px;
}

.field-value {
    font-size: 18px !important;
}

.field-label-main {
    font-weight: bold;
    margin-top: 3px;
    font-size: 18px;
}

.field-value-main {
    font-weight: bold;
    font-size: 18px !important;
    margin-right: 10px;
}
</style>