<template>
    <div>
        <div class="mb-4">For
            <ChargeTypeStatus :billingReport="billingReport" /> billing,
            identify the list of discounts and/or other charges within the billing period
            of <b>{{ startDate }}</b> and <b>{{ endDate }}</b>.
        </div>

        <b-row class="mt-4 mb-2">
            <b-col sm="6" md="3">
                <b-button variant="primary" @click="addAdjustment">Add Adjustment</b-button>
            </b-col>
            <b-col sm="6" md="4" offset-md="5" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table ref="adjustmentsTable" show-empty striped hover :items="items" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

            <template v-slot:cell(remarks)="row">
                <span class="truncate-text">
                    <truncate type="html" action-class="text-primary" :text="breakRemarks(row.item.remarks, 25)"
                        clamp="Show More" less="Show Less" :length="50" />
                </span>
            </template>

            <template v-slot:cell(amount)="row">
                <span class="numFont" v-if="row.item.type === 'Additional'">
                    {{ formatMoneyValue('PHP', row.item.amount) }}
                </span>
                <span class="numFont" v-if="row.item.type === 'Deduction'">
                    {{ formatMoneyValue('PHP', -1 * row.item.amount) }}
                </span>
            </template>

            <template v-slot:cell(actions)="row">
                <b-button size="sm" v-b-tooltip.hover.top="'Edit Adjustment'" variant="warning"
                    @click.stop="editAdjustment(row.item)" class="mr-1 mt-1">
                    <i class="fa fa-pencil"></i>
                </b-button>
                <b-button size="sm" v-b-tooltip.hover.top="'Delete Adjustment'" variant="danger"
                    @click.stop="deleteAdjustment(row.item)" class="mr-1 mt-1">
                    <em class="fa fa-trash"></em>
                </b-button>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <!-- Modals -->
        <AddBillingAdjustment :billingReport="billingReport" :adjustments="items" />
        <EditBillingAdjustment :billingReport="billingReport" :adjustments="items" />
        <DeleteBillingAdjustment @onDeleteAdjustment="onDeleteAdjustment" />
    </div>
</template>

<script>
// Components
import AddBillingAdjustment from './AddBillingAdjustment.vue';
import EditBillingAdjustment from './EditBillingAdjustment.vue';
import DeleteBillingAdjustment from './DeleteBillingAdjustment.vue';
import ChargeTypeStatus from '../ChargeTypeStatus.vue';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';


export default {
    name: 'billing-adjustments-tab',
    components: {
        AddBillingAdjustment,
        EditBillingAdjustment,
        DeleteBillingAdjustment,
        ChargeTypeStatus,
        truncate
    },
    props: {
        billingReport: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'type',
                    label: 'Adjustment Type',
                    sortable: false,
                },
                {
                    key: 'remarks',
                    sortable: false,
                },
                {
                    key: 'amount',
                    sortable: false,
                    class: 'text-right'
                },
                {
                    key: 'actions',
                    class: 'text-center'
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    computed: {
        startDate() {
            return DateUtil.getFormattedDate(this.billingReport.startDate);
        },
        endDate() {
            return DateUtil.getFormattedDate(this.billingReport.endDate);
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '';
        },
    },
    mounted() {
        EventBus.$on("onUpdateBillingAdjustments", async billingReport => {
            this.billingReport.adjustments = billingReport.adjustments;
            this.refreshTable();
        });

        EventBus.$on('onCloseAddAdjustment', adjustment => {
            // update id
            adjustment.id = this.items.length;

            // proceed updating the table
            this.updateTable(adjustment);
        });

        EventBus.$on('onCloseUpdateAdjustment', adjustment => {
            // proceed updating the table
            this.updateTable(adjustment);
        });
    },
    methods: {
        updateTable(adjustment) {
            this.billingReport.adjustments[adjustment.id] = adjustment;
            this.refreshTable();
            this.updateBillingComputation();
        },
        refreshTable() {
            this.items = this.billingReport.adjustments;
            this.totalRows = _.size(this.items);

            if (this.$refs.adjustmentsTable) {
                this.$refs.adjustmentsTable.refresh();
            }
        },
        updateBillingComputation() {
            this.billingReport.totalAdjustmentAmount = this.getTotalAdjustmentAmount();
            EventBus.$emit('onUpdateAdjustmentAmount', this.billingReport.totalAdjustmentAmount);
        },
        getTotalAdjustmentAmount() {
            let total = 0;

            _.forEach(this.billingReport.adjustments, adjustment => {
                if (adjustment.type === 'Additional') {
                    total += adjustment.amount;
                } else {
                    total -= adjustment.amount;
                }
            });

            return total;
        },

        addAdjustment() {
            EventBus.$emit('onAddAdjustment');
        },
        editAdjustment(adjustment) {
            let param = {
                adjustment: adjustment,
                billingReportNo: this.billingReportNo
            };
            EventBus.$emit('onEditAdjustment', param);
        },
        deleteAdjustment(adjustment) {
            let param = {
                adjustment: adjustment,
                billingReportNo: this.billingReportNo
            };
            EventBus.$emit('onDeleteAdjustment', param);
        },
        onDeleteAdjustment(adjustment) {
            let type = adjustment.type;
            let amount = adjustment && adjustment.amount ? 'PHP ' + adjustment.amount.toLocaleString() : '-';

            // delete
            this.billingReport.adjustments.splice(adjustment.id, 1);
            // update ids
            _.forEach(this.billingReport.adjustments, (adjustment, index) => {
                adjustment.id = index;
            });

            this.refreshTable();
            this.updateBillingComputation();

            // notify user
            this.$toaster.success(`${type} adjustment with amount of "${amount}" was deleted successfully.`);
        },

        // UTILS
        breakRemarks(remarks, length) {
            return remarks.length > length ? remarks.replace(new RegExp(`(.{1,${length}})\\s`, 'g'), '$1<br>') : remarks;
        },
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        },
    },
    beforeDestroy() {
        EventBus.$off('onUpdateBillingAdjustments');
        EventBus.$off('onCloseAddAdjustment');
        EventBus.$off('onCloseUpdateAdjustment');
    }
}
</script>

<style scoped>
.field-label-main {
    font-weight: bold;
    margin-top: 3px;
    font-size: 18px;
}

.field-value-main {
    font-weight: bold;
    font-size: 18px !important;
    margin-right: 10px;
}
</style>