<template>
    <div>
        <div class="mb-4">For
            <ChargeTypeStatus :billingReport="billingReport" /> billing,
            here are the list of transactions within the billing period
            of <b>{{ startDate }}</b> and <b>{{ endDate }}</b>.
        </div>

        <!-- Select Actions and Items Per Page Options -->
        <b-row class="mt-4 mb-2">
            <b-col sm="6" offset-sm="6" md="4" offset-md="8" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table ref="truckingTransactionsTable" show-empty striped hover :items="items" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

            <template v-slot:cell(dateReceived)="row">
                <span class="text-nowrap">{{ getFormattedDate(row.item.dateReceived) }}</span>
            </template>

            <template v-slot:cell(quantity)="row">
                <span class="numFont">{{ row.item.quantity.toLocaleString() }}</span>
            </template>

            <template v-slot:cell(dispatchNo)="row">
                <span class="numFont">
                    {{ row.item.dispatchNo }}
                </span>
            </template>

            <template v-slot:cell(notes)="row">
                <span class="truncate-text">
                    <truncate type="html" action-class="text-primary" :text="breakNotes(row.item.notes, 25)"
                        clamp="Show More" less="Show Less" :length="50" />
                </span>
            </template>

            <template v-slot:cell(truckType)="row">
                <span v-if="row.item.truckType === 'In-House'">
                    <b-badge variant="success">{{ row.item.truckType }}</b-badge>
                </span>
                <span v-if="row.item.truckType === 'Client-Owned'">
                    <b-badge variant="primary">{{ row.item.truckType }}</b-badge>
                </span>
                <span v-if="row.item.truckType === 'Outsourced'">
                    <b-badge variant="warning">{{ row.item.truckType }}</b-badge>
                </span>
            </template>

            <template v-slot:cell(transportation.plateNo)="row">
                <span class="numFont">
                    {{ row.item.transportation.plateNo }}
                </span>
            </template>

            <template v-slot:cell(billable)="row">
                <b-form-checkbox size="lg" v-model="row.item.billable" @change="onUpdateBillable(row)" :value="true"
                    :unchecked-value="false" />
            </template>

            <template v-slot:cell(vatExclusiveRate)="row">
                <b-row>
                    <b-col>
                        <b-form-group label="PHP" label-cols-sm="3" label-class="pt-2 px-1" label-align-sm="right">
                            <b-form-input name="VAT EX Rate" type="text" v-model="row.item.vatExclusiveRate"
                                v-validate="getVATExRateValidation(row.item)" placeholder="0.00"
                                :readonly="!row.item.billable" @keydown="blockInvalidCharacters" maxlength="7" />
                            <span v-show="errors.has('VAT EX Rate')" class="help-block">
                                {{ errors.first('VAT EX Rate') }}
                            </span>
                        </b-form-group>
                    </b-col>
                </b-row>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components
import ChargeTypeStatus from '../ChargeTypeStatus.vue';

// Utils
import { DateUtil } from '@/utils/dateutil';

// DAO
import dispatchDAO from '@/database/dispatches';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'billing-transactions-trucking-tab',
    components: {
        ChargeTypeStatus,
        Loading,
        truncate
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'dateReceived',
                    label: 'Date',
                    sortable: false,
                },
                {
                    key: 'dispatchNo',
                    label: 'Dispatch No.',
                    sortable: false,
                },
                {
                    key: 'notes',
                    sortable: false,
                },
                {
                    key: 'type',
                    sortable: false,
                },
                {
                    key: 'truckType',
                    label: 'Truck Type',
                    sortable: false,
                },
                {
                    key: 'transportation.plateNo',
                    label: 'Plate No./Conduction No.',
                    sortable: false,
                },
                {
                    key: 'transportation.company',
                    label: 'Company',
                    sortable: false,
                },
                {
                    key: 'billable',
                    label: 'Billable',
                    sortable: false,
                    class: 'text-center'
                },
                {
                    key: 'vatExclusiveRate',
                    label: 'VAT EX Rate',
                    sortable: false,
                    thClass: 'text-center'
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            billingReport: {},
            dispatches: {},

            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        startDate() {
            return DateUtil.getFormattedDate(this.billingReport.startDate);
        },
        endDate() {
            return DateUtil.getFormattedDate(this.billingReport.endDate);
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '';
        },
        assetOwnerId() {
            return this.billingReport && this.billingReport.assetOwnerId ? this.billingReport.assetOwnerId : '';
        },
        clientId() {
            return this.billingReport && this.billingReport.clientId ? this.billingReport.clientId : '';
        },
        clientAccountId() {
            return this.billingReport && this.billingReport.clientAccountId ? this.billingReport.clientAccountId : '';
        },
        dispatchIds() {
            if (this.items && !_.isEmpty(this.items)) {
                return _.map(this.items, 'dispatchId');
            }
            return [];
        }
    },
    watch: {
        items: {
            handler(newVal) {
                if (newVal && !_.isEmpty(newVal)) {
                    this.updateComputationSummary();
                }
            },
            deep: true
        }
    },
    async mounted() {
        // init billingReport
        this.billingReport = { ...this.$store.getters.currBillingReport };
        await this.onReset(this.billingReport);

        EventBus.$on("onUpdateTransactionsTrucking", async billingReport => {
            await this.onReset(billingReport);
        });
    },
    methods: {
        blockInvalidCharacters(event) {
            // Check if the key pressed corresponds to one of the blocked characters
            if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', 'Backspace', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
                // Prevent the default behavior (typing the character)
                event.preventDefault();
            }
        },
        getVATExRateValidation(item) {
            if (item.billable) {
                return {
                    required: true,
                    numeric: true,
                    min_value: 0.01,
                    max_value: 100000
                }
            } else {
                return {
                    required: false
                }
            }
        },
        async onReset(billingReport) {
            this.billingReport = billingReport;
            if (this.billingReport && !_.isEmpty(this.billingReport)) {
                // preload items from transactions
                this.items = this.billingReport.transactions;
                // retrieve other transactions not included to the original list
                await this.retrieveTransactions();
            }
        },
        async retrieveTransactions() {
            try {
                // show loading indicator
                this.isLoading = true;

                let filter = {
                    status: 'Received',
                    receivedStartDate: this.billingReport.startDate,
                    receivedEndDate: this.billingReport.endDate,
                    accountNo: { id: this.clientAccountId },
                };

                let sourceFilter = { ...filter };
                sourceFilter.sourceCompany = { id: this.assetOwnerId };
                sourceFilter.destinationCompany = { id: this.clientId };
                let sourceDispatches = await dispatchDAO.getDispatches(sourceFilter);

                let destinationFilter = { ...filter };
                destinationFilter.sourceCompany = { id: this.clientId };
                destinationFilter.destinationCompany = { id: this.assetOwnerId };
                let destinationDispatches = await dispatchDAO.getDispatches(destinationFilter);

                this.dispatches = { ...sourceDispatches, ...destinationDispatches };

                this.processDispatches(this.dispatches);
            } catch (error) {
                this.$toaster.error('Error loading transactions. Please reload the page again.');
            }

            // hide loading indicator
            this.isLoading = false;
        },
        processDispatches(dispatches) {
            _.forEach(dispatches, dispatch => {
                if (!this.dispatchIds.includes(dispatch.dispatchId)) {
                    let item = { ...dispatch };

                    let sourceCompanyId = dispatch.source.companyId;
                    item.type = sourceCompanyId === this.assetOwnerId ? 'Hire' : 'Dehire';

                    item.truckType = this.getTruckType(dispatch);
                    item.billable = item.truckType === 'In-House' ? true : false;
                    item.vatExclusiveRate = 0;

                    this.items.push(item);
                }
            });
            this.totalRows = this.items.length;

            // sort by ascending order
            this.items = _.sortBy(this.items, ['dateReceived']);
            // refresh table
            if (this.$refs.truckingTransactionsTable) {
                this.$refs.truckingTransactionsTable.refresh();
            }

            this.updateComputationSummary();
        },
        getTruckType(dispatch) {
            let companyId = dispatch.transportation.companyId;
            if (companyId === this.assetOwnerId) {
                return 'In-House';
            } else if (companyId === this.clientId) {
                return 'Client-Owned';
            } else {
                return 'Outsourced';
            }
        },

        updateComputationSummary() {
            // update transactions
            this.billingReport.transactions = this.items;
            // update total charge type amount
            this.billingReport.totalChargeTypeAmount = this.getTotalTruckingAmount();

            this.$emit('onUpdateBillingReport', this.billingReport);
            EventBus.$emit('onUpdateChargeTypeAmount', this.billingReport.totalChargeTypeAmount);
        },
        getTotalTruckingAmount() {
            let totalAmount = 0;
            _.forEach(this.items, item => {
                if (item.billable) {
                    totalAmount += parseFloat(item.vatExclusiveRate);
                }
            });
            return totalAmount;
        },

        onUpdateBillable(row) {
            if (!row.item.billable) {
                row.item.vatExclusiveRate = 0;
            }
        },

        // UTILS
        getFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        },
        breakNotes(notes, length) {
            return notes.length > length ? notes.replace(new RegExp(`(.{1,${length}})\\s`, 'g'), '$1<br>') : notes;
        },
    },
    beforeDestroy() {
        EventBus.$off('onUpdateTransactionsTrucking');
    },
}
</script>

<style scoped>
.field-label-main {
    font-weight: bold;
    margin-top: 3px;
    font-size: 18px;
}

.field-value-main {
    font-weight: bold;
    font-size: 18px !important;
    margin-right: 10px;
}
</style>