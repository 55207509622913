<template>
    <b-modal id="delete-billing-adjustment" :title="title" ref="modal" ok-title="Delete" @ok="handleOk" @show="onReset"
        :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <div class="confirm-message">
            Are you sure you want to delete <strong>{{ type }}</strong> adjustment with amount of
            <span class="numFont"><strong>{{ amount }}</strong></span>
            for billing report <span class="numFont"><strong>{{ billingReportNo }}</strong></span>?
        </div>
    </b-modal>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'delete-billing-adjustment',
    components: {
        Loading
    },
    data() {
        return {
            adjustment: {},
            billingReportNo: '',

            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        title() {
            return 'Delete Adjustment'
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        type() {
            return this.adjustment && this.adjustment.type ? this.adjustment.type : '-';
        },
        amount() {
            return this.adjustment && this.adjustment.amount ? this.formatMoneyValue('PHP', this.adjustment.amount) : '-';
        }
    },
    mounted() {
        EventBus.$on('onDeleteAdjustment', (param) => {
            this.$bvModal.show('delete-billing-adjustment');
            this.onReset(param);
        });
    },
    methods: {
        handleOk() {
            this.$emit("onDeleteAdjustment", this.adjustment);
        },
        onReset(param) {
            this.adjustment = param.adjustment;
            this.billingReportNo = param.billingReportNo;
        },

        // UTILS
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        }
    },
    beforeDestroy() {
        EventBus.$off('onDeleteAdjustment');
    }
}
</script>