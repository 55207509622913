<template>
    <b-modal id="add-billing-adjustment" :title="title" ref="modal" ok-title="Add" @ok="handleOk" @show="onReset"
        :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-form @submit.stop.prevent="handleSubmit" novalidate>
            <b-container fluid>
                <b-row class="mt-2" no-gutters>
                    <b-col sm="12">
                        <b-form-group label="Adjustment Type">
                            <b-form-radio-group name="Adjustment Type" v-model="adjustment.type"
                                :options="typeOptions" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2" no-gutters>
                    <b-col sm="12">
                        <b-form-group label="Amount (PHP)" label-for="Amount">
                            <b-form-input id="amount" name="Amount" type="number" class="numFont"
                                v-model="adjustment.amount" v-validate="'required|min_value:0.01'" min="0.01"
                                step="0.01" onwheel="this.blur()" placeholder="0" @keydown="blockInvalidCharacters" />
                            <span v-show="errors.has('Amount')" class="help-block">
                                {{ errors.first('Amount') }}
                            </span>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2" no-gutters>
                    <b-col sm="12">
                        <b-form-group label="Remarks" label-for="Remarks" description>
                            <b-form-textarea name="Remarks" type="text" v-model="adjustment.remarks" maxlength="200"
                                v-validate="getValidationParam(true, remarksRegex)" :rows="3" placeholder="Remarks" />
                            <span v-show="errors.has('Remarks')" class="help-block">
                                {{ errors.first('Remarks') }}
                            </span>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>
        </b-form>
    </b-modal>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'add-billing-adjustment',
    components: {
        Loading
    },
    props: {
        billingReport: {
            type: Object,
            required: true
        },
        adjustments: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            adjustment: {
                type: 'Additional',
                amount: 0,
                remarks: ''
            },

            typeOptions: [
                { text: 'Additional', value: 'Additional' },
                { text: 'Deduction', value: 'Deduction' },
            ],

            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        title() {
            return 'Add Adjustment'
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        remarksRegex() {
            return config.remarksRegex;
        },
        type() {
            return this.adjustment && this.adjustment.type ? this.adjustment.type : '-';
        },
        amount() {
            return this.adjustment && this.adjustment.amount ? 'PHP ' + this.adjustment.amount.toLocaleString() : '-';
        },
        totalChargeTypeAmount() {
            return this.billingReport && this.billingReport.totalChargeTypeAmount ? this.billingReport.totalChargeTypeAmount : 0;
        }
    },
    mounted() {
        EventBus.$on('onAddAdjustment', () => {
            this.$bvModal.show('add-billing-adjustment');
        });
    },
    methods: {
        getValidationParam(isRequired, regex) {
            return {
                required: isRequired,
                regex: regex,
            };
        },
        blockInvalidCharacters(event) {
            // Check if the key pressed corresponds to one of the blocked characters
            if (event.key === '+' || event.key === '-' || event.key === 'e') {
                // Prevent the default behavior (typing the character)
                event.preventDefault();
            }
        },

        getTotalAdditional(adjustments, adjustment) {
            let totalAdditional = adjustment.type === 'Additional' ? parseFloat(adjustment.amount) : 0;
            _.forEach(adjustments, o => {
                totalAdditional += o.type === 'Additional' ? parseFloat(o.amount) : 0;
            });
            return totalAdditional;
        },
        getTotalDeduction(adjustments, adjustment) {
            let totalDeduction = adjustment.type === 'Deduction' ? parseFloat(adjustment.amount) : 0;
            _.forEach(adjustments, o => {
                totalDeduction += o.type === 'Deduction' ? parseFloat(o.amount) : 0;
            });
            return totalDeduction;
        },
        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            // show loading indicator
            this.isLoading = true;

            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                this.isLoading = false;
                return;
            }

            let totalDeduction = this.getTotalDeduction(this.adjustments, this.adjustment);
            let totalChargeTypeAmount = this.totalChargeTypeAmount + this.getTotalAdditional(this.adjustments, this.adjustment);
            if (totalDeduction > totalChargeTypeAmount) {
                this.$toaster.warning('Total Deduction Amount, ' + this.formatMoneyValue('PHP', totalDeduction) + ', exceeded the VAT Exclusive Amount');
                this.isLoading = false;
                return;
            }

            this.handleSubmit();
        },
        handleSubmit() {
            try {
                // show loading indicator
                this.isLoading = true;

                // parse number
                this.adjustment.amount = parseFloat(this.adjustment.amount);

                this.$toaster.success(`${this.type} adjustment with amount of "${this.amount}" was added successfully.`);
                EventBus.$emit('onCloseAddAdjustment', this.adjustment);
                this.$refs.modal.hide();

            } catch (error) {
                this.$toaster.error(`Error adding adjustment "${this.type}". Please try again.`);
            }

            // hide loading indicator
            this.isLoading = false;
        },

        onReset() {
            // reset adjustment
            this.adjustment = {
                type: 'Additional',
                amount: 0,
                remarks: ''
            };

            // reset validation
            this.isLoading = false;
            this.$validator.reset();
            this.errors.clear();
        },

        // UTILS
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        },
    },
    beforeDestroy() {
        EventBus.$off('onAddAdjustment');
    }
}
</script>